.discords {
  section {
    > h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    .cardContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @media (max-width: 1300px) {
      > h1 { text-align: center };
  
      .cardContainer { justify-content: center };
    }
  }
}