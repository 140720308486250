.eventCard {
  background-color: #36393f;
  border-radius: 10px;
  margin: 0 10px 40px;

  flex: 1 1 auto;
  width: 28%;
  max-width: 400px;
  min-width: 325px;

  > div {
    background-size: cover;
    background-position: top center;
    height: 225px;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0 0;
  }

  img {
    border-radius: 10px 10px 0 0;
    width: 100%;
  }

  main {
    padding: 10px 20px;
    h1 {
      font-size: 26px;
      margin: 0;
    }
    h3 {
      font-size: 17px;
      margin: 0;
    }
    h4 {
      font-size: 15px;
      margin: 0 0 18px;
    }

    h2 {
      font-size: 20px;
      margin: 0;
    }
    p {
      margin: 0;
    }
    .links {
      margin-top: 10px;
    }
  }
}