.socials {
  section {
    background: #36393f;
    border-radius: 10px;

    display: flex;
    justify-content: space-around;
    max-width: 600px;
    margin: 50px auto;
    flex-wrap: wrap;

    img {
      flex: 0 1 auto;
      margin: 20px;
      width: 130px;
    }

    @media (max-width: 1300px) {
      > h1 { text-align: center };
  
      .cardContainer { justify-content: center };
    }
  }
}