.App .events {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .noEventsContainer {
    display: block;
    text-align: center;
    color: #d0d0d0;
  }
}