.discordCard {
  width: 28%;
  min-width: 310px;
  max-width: 500px;
  background-color: #36393f;
  color: white;
  border-radius: 10px;
  margin: 0 20px 20px 0;
  padding: 16px;
  
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  .cardTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      margin-left: 10px;
      h1 {
        margin: 0;
        font-size: 25px;
      }
     
      a {
        color: #72da78;
        font-size: 18px;
      }
    }
    img {
      border-radius: 100%;
      max-width: 80px;
      max-height: 80px;
    }
  }
  
  p {
    margin: 10px 0 0;
  }

  @media (max-width: 750px) {
    margin: 0 0 20px 0;
  }
}