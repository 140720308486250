.App {
  padding: 0;
  color: #ffffff;
  article {
    margin: 0 auto 40px;
    width: 90%;
  }
  a {
    color: #72da78; 
  }
}