.regions {
  section {
    width: 90%;
    margin: 45px auto;
    max-width: 900px;
    background: #36393f;
    border-radius: 10px;

    header {
      width: 100%;
      height: 40vw;
      max-height: 470px;
      background-size: cover;
      background-position: center;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px white solid;
      display: flex;
      flex-direction: column;

      h1 {
        font-size:  calc(clamp(41px, 10vw, 100px));
        font-family: 'Teko', sans-serif;
        padding-left: 20px;
        flex: 1 1 auto;
        margin: 0;
        text-shadow: -1px -1px 0 #36393f, 1px -1px 0 #36393f, -1px 1px 0 #36393f, 1px 1px 0 #36393f;
      }
      p {
        padding-right: 20px;
        align-self: end;
        -webkit-align-self: flex-end;
      }
    }
    main {
      padding: 20px 30px 0;
      h3 {
        margin: 0;
      }
      p {
        margin-top: 5px;
        &:last-of-type {
          margin-bottom: 35px;
        }
      }
    }

    footer {
      text-align: right;
      padding: 0 20px 10px;
      
      img {
        width: 40px;
      }
    }
    
  }
}