.NavBar {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 0 auto;
  a {
    font-size: 20px;
    color: #ffffff;
    margin: 0 10px;
  }
}
